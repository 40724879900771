.header {
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title.video {
  font-family: var(--font-vcr);
}
