.imageWrapper {
  max-width: var(--width);
  height: var(--height);
  margin-inline-end: 16px;

  display: inline-block;
  scroll-snap-align: center;
  position: relative;
  background: var(--blur, transparent);
  background-repeat: no-repeat;
  background-size: contain;
}
