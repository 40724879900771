.slider {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
}
@media (min-width: 990px) {
  .slider {
    composes: sliderScrollbar from '@/css/sliderScrollbar.module.css';
    display: block;
    position: relative;
    inline-size: 100%;
    white-space: nowrap;
    overflow: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    height: var(--slider-default-height);
  }
}

@media (max-width: 990px) {
  .image, .image img {
    box-sizing: border-box;
    width: 100%;
    height: auto!important;
  }
}

@media (min-width: 990px) {
  .image img {
    object-fit: contain;
    width: var(--width);
    height: var(--slider-default-height);
  }
}
